import { useNavigate } from "react-router-dom";
import { sleep } from "../common/utils/sleep";

export default function useScrollToContact() {
  const navigate = useNavigate();
  const scrollToContact = async () => {
    const handleHomeRedirect = () => {
      navigate("/");
    };

    const id = "contact";
    const element = document.getElementById(id) as HTMLDivElement;
    console.log(element);
    if (!element) {
      handleHomeRedirect();
      await sleep(1000); // temp
      scrollToContact();
    } else {
      element.scrollIntoView({
        behavior: "smooth",
      });
    }
  };

  return scrollToContact;
}
