import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { SvgIcon } from "../../common/SvgIcon";
import Container from "../../common/Container";

import i18n from "i18next";
import {
  FooterSection,
  Title,
  NavLink,
  Extra,
  LogoContainer,
  Para,
  Large,
  Chat,
  Language,
  Label,
  LanguageSwitch,
  LanguageSwitchContainer,
  FullPara,
} from "./styles";
import { ADDRESS, PHONE_NUMBER } from "../../constants";
import useScrollToContact from "../../hooks/useScrollToContact";

const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  const scrollToContact = useScrollToContact();

  // const SocialLink = ({ href, src }: SocialLinkProps) => {
  //   return (
  //     <a
  //       href={href}
  //       target="_blank"
  //       rel="noopener noreferrer"
  //       key={src}
  //       aria-label={src}
  //     >
  //       <SvgIcon src={src} width="25px" height="25px" />
  //     </a>
  //   );
  // };

  return (
    <>
      <FooterSection>
        <Container>
          <Row justify="space-between">
            <Col lg={4} md={4} sm={12} xs={12}>
              <Language>{t("Contact")}</Language>
              <Para>
                {t(`Do you have any question? Feel free to reach out.`)}
              </Para>
              <Chat onClick={scrollToContact}>Napisz do nas!</Chat>
            </Col>
            <Col lg={8} md={8} sm={12} xs={12}>
              <Language>{t("Address")}</Language>
              <FullPara>RO-SZ DENT SP. Z O.O.</FullPara>
              <FullPara>{ADDRESS}</FullPara>
              <FullPara>NIP: 583-340-66-80</FullPara>
              <FullPara>PKO BP: 26 1020 1811 0000 0002 0370 5019</FullPara>
              <FullPara>
                {t("Phone")}: {PHONE_NUMBER}
              </FullPara>
            </Col>
            <Col lg={4} md={4} sm={12} xs={12}>
              <Title>{t("Company")}</Title>
              <Large left="true" to="/about">
                {t("About")}
              </Large>
              <Large left="true" to="/privacy-policy">
                {t("Privacy Policy")}
              </Large>
              <Large left="true" to="/faq">
                {t("FAQ")}
              </Large>
            </Col>
            <Col lg={3} md={3} sm={12} xs={12}>
              <Label htmlFor="select-lang">{t("Language")}</Label>
              <LanguageSwitchContainer>
                <LanguageSwitch onClick={() => handleChange("pl")}>
                  <SvgIcon
                    src="poland.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch>
                {/* <LanguageSwitch onClick={() => handleChange("en")}>
                  <SvgIcon
                    src="england.svg"
                    aria-label="homepage"
                    width="30px"
                    height="30px"
                  />
                </LanguageSwitch> */}
              </LanguageSwitchContainer>
            </Col>
          </Row>
        </Container>
      </FooterSection>
      <Extra>
        <Container border={true}>
          <Row
            justify="space-between"
            align="middle"
            style={{ paddingTop: "3rem" }}
          >
            <NavLink to="/">
              <LogoContainer>
                <SvgIcon
                  src="logo.png"
                  aria-label="homepage"
                  width="86px"
                  height="80px"
                />
              </LogoContainer>
            </NavLink>
          </Row>
        </Container>
      </Extra>
    </>
  );
};

export default withTranslation()(Footer);
