/* eslint-disable @typescript-eslint/no-unused-vars */
import { lazy, Suspense } from "react";
import { Routes, Route } from "react-router-dom";
import Footer from "../components/Footer";
import Header from "../components/Header";
import routes from "./config";
import { Styles } from "../styles/styles";
import emailjs from "@emailjs/browser";

const components = routes.map((routeItem) => {
  return lazy(() => import(`../pages/${routeItem.component}`));
});

emailjs.init({
  publicKey: "",
  blockHeadless: true,
  blockList: {
    // Block the suspended emails
    list: [],
    // The variable contains the email address
    watchVariable: "userEmail",
  },
  limitRate: {
    // Set the limit rate for the application
    id: "app",
    // Allow 1 request per 10s
    throttle: 10000,
  },
});

const Router = () => {
  return (
    <Suspense fallback={null}>
      <Styles />
      <Header />
      <Routes>
        {routes.map((routeItem) => {
          const LazyComponent = lazy(
            () => import(`../pages/${routeItem.component}`)
          );
          return <Route path={routeItem.path} element={<LazyComponent />} />;
        })}
      </Routes>
      <Footer />
    </Suspense>
  );
};

export default Router;
