const routes = [
  {
    path: "/",
    exact: true,
    component: "Home",
  },
  {
    path: "/products",
    exact: true,
    component: "Products",
  },
  {
    path: "/products/:id",
    exact: true,
    component: "ProductDetails",
  },
  {
    path: "/privacy-policy",
    exact: true,
    component: "PrivacyPolicy",
  },
  {
    path: "/faq",
    exact: true,
    component: "Faq",
  },
  {
    path: "/about",
    exact: true,
    component: "About",
  },
  {
    path: "/pricing",
    exact: true,
    component: "Pricing",
  },
];

export default routes;
