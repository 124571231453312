import { useState } from "react";
import { Row, Col, Drawer } from "antd";
import { withTranslation } from "react-i18next";
import Container from "../../common/Container";
import { Button } from "../../common/Button";
import {
  HeaderSection,
  LogoContainer,
  Burger,
  NotHidden,
  CustomNavLinkSmall,
  Label,
  Outline,
  Span,
} from "./styles";
import { SvgIcon } from "../../common/SvgIcon";
import { useNavigate } from "react-router-dom";
import { sleep } from "../../common/utils/sleep";

const Header = ({ t }: any) => {
  const [visible, setVisibility] = useState(false);
  const navigate = useNavigate();

  const handleHomeRedirect = async () => {
    navigate("/");
  };

  const handleProductsRedirect = () => {
    navigate("/products");
  };
  const handleFaqRedirect = () => {
    navigate("/faq");
  };
  const handleAboutRedirect = () => {
    navigate("/about");
  };
  const handlePricingRedirect = () => {
    navigate("/pricing");
  };

  const showDrawer = () => {
    setVisibility(!visible);
  };

  const onClose = () => {
    setVisibility(!visible);
  };

  const MenuItem = () => {
    const scrollTo = async (id: string) => {
      const element = document.getElementById(id) as HTMLDivElement;
      if (!element) {
        handleHomeRedirect();
        await sleep(1000); // temp
        scrollTo(id);
      } else {
        element.scrollIntoView({
          behavior: "smooth",
        });
      }
      setVisibility(false);
    };

    // function changeLanguage(language: string) {
    //   i18n.changeLanguage(language);
    // }

    return (
      <>
        <CustomNavLinkSmall onClick={handleAboutRedirect}>
          <Span>{t("About")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={handleFaqRedirect}>
          <Span>{t("FAQ")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={handleProductsRedirect}>
          <Span>{t("Product")}</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={() => scrollTo("contact")}>
          <Span>Zamów katalog</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall onClick={handlePricingRedirect}>
          <Span>Cennik</Span>
        </CustomNavLinkSmall>
        <CustomNavLinkSmall
          style={{ width: "150px" }}
          onClick={() => scrollTo("contact")}
        >
          <Span>
            <Button>{t("Contact")}</Button>
          </Span>
        </CustomNavLinkSmall>
        {/* <CustomNavLinkSmall> */}
        {/* <LanguageSwitchContainer> */}
        {/* <LanguageSwitch onClick={() => changeLanguage("pl")}>
              <SvgIcon
                src="poland.svg"
                aria-label="homepage"
                width="30px"
                height="30px"
              />
            </LanguageSwitch> */}
        {/* <LanguageSwitch onClick={() => changeLanguage("en")}>
              <SvgIcon
                src="england.svg"
                aria-label="homepage"
                width="30px"
                height="30px"
              />
            </LanguageSwitch> */}
        {/* </LanguageSwitchContainer> */}
        {/* </CustomNavLinkSmall> */}
      </>
    );
  };

  return (
    <HeaderSection id="header">
      <Container>
        <Row justify="space-between">
          <LogoContainer to="/" aria-label="homepage">
            <SvgIcon
              src="logo.png"
              aria-label="homepage"
              width="111px"
              height="101px"
            />
          </LogoContainer>
          <NotHidden>
            <MenuItem />
          </NotHidden>
          <Burger onClick={showDrawer}>
            <Outline />
          </Burger>
        </Row>
        <Drawer closable={false} open={visible} onClose={onClose}>
          <Col style={{ marginBottom: "2.5rem" }}>
            <Label onClick={onClose}>
              <Col span={12}>
                <LogoContainer to="/" aria-label="homepage">
                  <SvgIcon
                    src="logo.png"
                    aria-label="homepage"
                    width="56px"
                    height="50px"
                  />
                </LogoContainer>
              </Col>
              <Col span={12}>
                <Outline />
              </Col>
            </Label>
          </Col>
          <MenuItem />
        </Drawer>
      </Container>
    </HeaderSection>
  );
};

export default withTranslation()(Header);
